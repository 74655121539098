import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// Containers
import { Content, OtherTributes } from "../containers/tributeSingle"

const TributeTemplate = ({ data }) => {
  const {
    id,
    location,
    author,
    sliderContent,
    sliderVideos,
    tributeContent,
    mom,
  } = data.tribute

  const { edges } = data.otherTributes
  const otherTributes = edges.filter(tribute => tribute.node.id !== id)

  const contentData = {
    id,
    location,
    author,
    sliderContent,
    sliderVideos,
    tributeContent,
    mom,
  }

  return (
    <Layout>
      <SEO
        title={`${mom.firstName} ${mom.lastName} | Tribute2Moms`}
        image={mom.image}
      />
      <Content
        data={contentData}
        noStroke={otherTributes.length < 1 ? true : false}
      />
      <OtherTributes tributes={otherTributes} />
    </Layout>
  )
}

export const query = graphql`
  query TributeTemplatePageQuery($momID: String, $id: String) {
    tribute: contentfulTributes(id: { eq: $id }) {
      id
      location
      author
      sliderContent {
        id
        title
        file {
          url
        }
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      sliderVideos {
        id
        title
        file {
          url
        }
      }
      tributeContent {
        tributeContent
      }
      mom {
        firstName
        lastName
        birthDate(formatString: "MMM DD")
        image {
          fluid {
            src
          }
        }
      }
    }
    otherTributes: allContentfulTributes(
      limit: 3
      filter: { mom: { id: { eq: $momID } } }
    ) {
      edges {
        node {
          id
          author
          sliderContent {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          mom {
            firstName
            lastName
          }
        }
      }
    }
  }
`

export default TributeTemplate
