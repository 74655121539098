import React from "react"

// Components
import { ArticlesList } from "../../../containers/home/articlesSection"
import AdSection from "../../../components/adSection"

// Styles
import styles from "./index.module.scss"

const OtherTributes = ({ tributes }) => {
  return (
    <div className={styles.otherTributes}>
      {tributes.length >= 1 && (
        <>
          <div className="max-w-6xl mx-auto px-4 lg:px-0">
            <h2>Other tributes for this mom</h2>
            <div className={styles.articleListContainer}>
              <ArticlesList data={tributes} tributesView={true} />
            </div>
          </div>
        </>
      )}
      {/* <AdSection /> */}
    </div>
  )
}

export default OtherTributes
