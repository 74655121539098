import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Swiper from "react-id-swiper"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import {
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
} from "react-share"

// Components
import {
  PlayIcon,
  FacebookColorIcon,
  TwitterColorIcon,
  PinterestColorIcon,
  LeftArrow,
  LeftHoverArrow,
  RightArrow,
  RightHoverArrow,
} from "../../../components/icons"

// Styles
import styles from "./index.module.scss"

// Images

import stroke from "../../../images/stroke1.svg"
import hearts from "../../../images/hearts.svg"

const Details = ({ data: { mom, dob, birthplace, author } }) => (
  <div className="md:flex justify-between items-center mb-8">
    <div className={styles.personalDetails}>
      <h4 className={styles.tributeMom}>
        <span>{mom.trim()}</span>, {birthplace}
      </h4>
      <p>
        <span>Birthday: </span>
        {dob}
      </p>
    </div>
    <div className={styles.authorDetails}>
      <p>posted by</p>
      <p className={styles.author}>{author}</p>
    </div>
  </div>
)

const Title = ({ title }) => (
  <div className="md:flex justify-between items-center my-8">
    <div className={styles.personalDetails}>
      <h4 className={styles.tributeMom}>
        <span>{title}</span>
      </h4>
    </div>
  </div>
)

const Paginate = ({ direction, swiper, index, smallSwiper }) => (
  <div
    className={`${styles.paginate} ${
      direction === "left" ? styles.left : styles.right
    }`}
    onClick={() => {
      if (swiper && swiper.params) {
        swiper.slideTo(direction === "left" ? index - 1 : index + 1, 1000)
        smallSwiper.slideTo(direction === "left" ? index - 1 : index + 1, 1000)
      }
    }}
  >
    {direction === "left" && (
      <>
        <LeftArrow className={styles.normal} />
        <LeftHoverArrow className={styles.hovered} />
      </>
    )}
    {direction === "right" && (
      <>
        <RightArrow className={styles.normal} />
        <RightHoverArrow className={styles.hovered} />
      </>
    )}
  </div>
)

const Slider = ({ data }) => {
  const [swiper, setSwiper] = useState(null)
  const [smallSwiper, setSmallSwiper] = useState(null)
  const [video, setVideo] = useState(false)
  const [swiperIndex, setSwiperIndex] = useState(0)
  const params = {
    slidesPerView: 1,
    spaceBetween: 30,
    runCallbacksOnInit: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    onInit: swiper => {
      setSwiper(swiper)
    },
    on: {
      slideChange: function (e) {
        setVideo(false)
        setSwiperIndex(e.activeIndex)
      },
    },
  }
  const paginationParams = {
    slidesPerView: 6,
    spaceBetween: 30,
    onInit: swiper => {
      setSmallSwiper(swiper)
    },
  }

  return (
    <div className={styles.sliderSection}>
      <div className={styles.slider}>
        <Swiper
          {...params}
          ref={node => {
            if (node) setSwiper(node.swiper)
          }}
        >
          {data.map(slider => {
            return slider.fluid ? (
              <BackgroundImage
                key={slider.id}
                fluid={slider.fluid || slider.file.url}
                style={{ backgroundSize: "contain" }}
              />
            ) : (
              <div key={slider.id} className="relative">
                {!video && (
                  <PlayIcon
                    className={`${styles.icon} ${video ? styles.hidden : ""}`}
                    onClick={() => setVideo(true)}
                  />
                )}
                <video
                  controls={video}
                  src={slider.file.url}
                  className="w-full h-full"
                ></video>
              </div>
            )
          })}
        </Swiper>
        {}
      </div>
      <div className={styles.pagination}>
        <Swiper
          {...paginationParams}
          ref={node => {
            if (node) setSmallSwiper(node.swiper)
          }}
        >
          {data.map((slider, index) => {
            return slider.fluid ? (
              <div
                key={index}
                className={`${styles.paginationSlider} ${
                  swiperIndex === index ? styles.active : ""
                } `}
                onClick={() => {
                  if (swiper && swiper.params) {
                    swiper.slideTo(index, 1000)
                  }
                }}
              >
                <BackgroundImage
                  key={slider.id}
                  fluid={slider.fluid || slider.file.url}
                  style={{
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                  className="h-full"
                />
              </div>
            ) : (
              <div
                key={index}
                className={`${styles.paginationSlider} ${
                  swiperIndex === index ? styles.active : ""
                } `}
                onClick={() => {
                  if (swiper && swiper.params) {
                    swiper.slideTo(index, 1000)
                  }
                }}
              >
                <video src={slider.file.url} className="w-full h-full"></video>
              </div>
            )
          })}
        </Swiper>
        {data.length > 6 && (
          <>
            {swiperIndex + 1 !== data.length && (
              <Paginate
                direction="right"
                swiper={swiper}
                index={swiperIndex}
                smallSwiper={smallSwiper}
              />
            )}

            {swiperIndex > 0 && (
              <Paginate
                direction="left"
                swiper={swiper}
                index={swiperIndex}
                smallSwiper={smallSwiper}
              />
            )}
          </>
        )}
      </div>
    </div>
  )
}

const Content = ({ data, url, socialDetails: { ogtitle }, image }) => {
  return (
    <div className={styles.content}>
      <div
        className={styles.contentContainer}
        dangerouslySetInnerHTML={{ __html: data }}
      />

      <div className={styles.socialShareContainer}>
        <h4>Share Tribute</h4>
        <div className={styles.share}>
          <FacebookShareButton url={url}>
            <FacebookColorIcon className="mr-4" />
          </FacebookShareButton>
          <TwitterShareButton
            url={url}
            title={ogtitle}
            hashtags={[ogtitle.split(" ").join("")]}
          >
            <TwitterColorIcon className="mr-4" />
          </TwitterShareButton>
          <PinterestShareButton media={image.fluid.src} url={url}>
            <PinterestColorIcon />
          </PinterestShareButton>
        </div>
      </div>
    </div>
  )
}

const TributeDetailsSection = ({
  data: {
    id,
    location,
    author,
    sliderContent,
    sliderVideos,
    tributeContent,
    mom: { firstName, lastName, birthDate, image },
  },
  noStroke,
}) => {
  const detailSectionData = {
    mom: `${firstName} ${lastName}`,
    birthplace: location,
    dob: birthDate,
    author,
  }

  const { url, social } = useStaticQuery(graphql`
    query URLquery {
      url: site {
        siteMetadata {
          siteUrl
        }
      }
      social: contentfulWebsiteDetails {
        ogtitle
      }
    }
  `)

  const sharingUrl = `${
    url.siteMetadata.siteUrl
  }/tribute/${firstName.toLowerCase()}-${lastName.toLowerCase()}/${id}`

  return (
    <div className={styles.tributeDetails}>
      <img src={hearts} className={styles.heartImg} />
      <div className="max-w-6xl mx-auto px-4 xl:px-0">
        <Details data={detailSectionData} />
        <Content
          momName={`${firstName} ${lastName}`}
          data={tributeContent.tributeContent}
          url={sharingUrl}
          socialDetails={social}
          image={image}
        />
        <Title title="Photos" />
        <Slider data={sliderContent} />
        {sliderVideos?.length > 0 && (
          <>
            <Title title="Videos" />
            <Slider data={sliderVideos} />
          </>
        )}
      </div>
      {!noStroke && (
        <img src={stroke} alt="horizontal stroke" className={styles.stroke} />
      )}
    </div>
  )
}

export default TributeDetailsSection
